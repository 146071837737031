import * as React from 'react';
import {faSearch, faShoppingCart} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ReactNode} from 'react';

type Variants = `disabled` | `light` | `link` | `outline-light` | `outline-primary` | `outline-secondary` | `primary` | `secondary`;

interface ButtonProps {
    block?: boolean;
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    e2e?: string;
    icon?: `cart` | `none` | `search`;
    modal?: boolean;
    onClick?: (event?) => void;
    size?: `sm` | `lg`;
    type?: `button` | `reset` | `submit`;
    variant?: Variants;
}

const buttonStyles = `tw-rounded-sm tw-justify-center tw-items-center tw-relative tw-font-normal tw-text-center`;

const variantStyles: Record<Variants, any> = {
    disabled: {
        button: `tw-opacity-50`,
    },
    light: {
        button: `tw-border tw-border-gray-200 tw-bg-gray-200 tw-text-gray-600 hover:tw-bg-gray-250`,
    },
    link: {
        button: `tw-text-blue-200 tw-bg-transparent`,
    },
    'outline-light': {
        button: `tw-border tw-border-gray-200 tw-bg-transparent active:tw-bg-gray-250 tw-text-gray-200 hover:tw-bg-gray-200 hover:tw-text-gray-600`,
    },
    'outline-primary': {
        button: `tw-border tw-border-red-500 tw-bg-white active:tw-bg-red-600 tw-text-red-500 hover:tw-bg-red-500 hover:tw-text-white`,
    },
    'outline-secondary': {
        button: `tw-border tw-border-gray-600 tw-bg-transparent hover:tw-bg-gray-600 tw-text-gray-650 hover:tw-text-white`,
    },
    primary: {
        button: `tw-bg-red-500 active:tw-bg-red-600 hover:tw-bg-red-600 tw-text-white`,
    },
    secondary: {
        button: `tw-border tw-border-gray-600 tw-bg-gray-600 hover:tw-bg-gray-650 tw-text-white`,
    },
};

const Button: React.FC<ButtonProps> = ({
    block,
    children,
    className,
    disabled = false,
    e2e,
    icon = `none`,
    modal = false,
    onClick,
    size = `lg`,
    type = `button`,
    variant = `secondary`,
}) => {
    const {button} = variantStyles[variant];

    const btnStyles = [
        buttonStyles,
        button,
        disabled ? variantStyles.disabled.button : null,
        block ? `tw-w-full` : `tw-w-auto`,
        modal
            ? `focus:tw-shadow-[0_0_0_0.2rem_rgba(108,117,125,0.5)] !tw-rounded tw-w-full lg:tw-w-auto tw-inline-block tw-px-4 tw-text-xl tw-leading-normal tw-transition-colors tw-mb-2 tw-ease-in`
            : ``,
        size === 'lg' ? `tw-px-2.5 tw-h-[52px] tw-text-xl` : `tw-px-2 tw-h-[38px] tw-text-base`,
    ]
        .filter(Boolean)
        .flat()
        .join(' ');

    return (
        <button
            className={`${className} ${btnStyles}`}
            data-e2e={e2e ? e2e : undefined}
            disabled={disabled}
            onClick={onClick}
            type={type}
        >
            {icon !== `none` ? <FontAwesomeIcon icon={icon === `search` ? faSearch : faShoppingCart} /> : <>{children}</>}
        </button>
    );
};

export default Button;
