if (typeof window !== `undefined`) {
    // window check was needed to make sure nodejs does not try to load the .scss resource
    require('./ProgressOverlay.scss');
}

export class ProgressOverlay {
    /**
     * Activates the ProgressOverlay
     * Call when you want to show the UI is waiting for server response
     */
    public static activate(transparent?: boolean, helperText?: string) {
        // Deactivate any existing overlays
        this.deactivate();
        let showHelperText = '';
        if (helperText) {
            showHelperText = `<div class="gray-100">${helperText}</div>`;
        }

        // Display overlay
        if (transparent) {
            document
                .querySelector(`body`)
                .insertAdjacentHTML(
                    `beforeend`,
                    `<div class='transparentOverlay' style='height:100vh;width:100vw;position:absolute;z-index: 2000000000000;'></div>`,
                );
        } else {
            document
                .querySelector(`body`)
                .insertAdjacentHTML(
                    `beforeend`,
                    `<div class='progressOverlay d-flex align-items-center justify-content-center flex-column'><div class='spinner'></div>${showHelperText}</div>`,
                );
        }
    }

    /**
     * Deactivates the ProgressOverlay
     * Call when you want to return control
     */
    public static deactivate() {
        const elem = document.querySelector(`.progressOverlay, .transparentOverlay`);
        if (elem) {
            elem.parentNode.removeChild(elem);
        }
    }
}
